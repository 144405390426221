import React, { useEffect, useMemo, useRef, useState } from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { FormControl, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { Flag } from '@shared/components/new-design/input-phone/components/flag/flag';
import { RemoveButton } from '@shared/components/new-design/input-text/components/remove-button/remove-button';
import { PhoneConverter } from '@shared/utils/phone-converter';
import { UseFormRegisterReturn } from 'react-hook-form';

import { StyledFormHelperText, styles } from './input-phone.styles';

export type InputPhoneFieldProps = AppWithStyles<any> &
  TextFieldProps & {
    resetValidation?: () => void;
    children?: React.ReactNode;
    controls: UseFormRegisterReturn;
    defaultValue?: string;
  };

const InputPhoneComponent: React.FC<InputPhoneFieldProps> = ({
  classes,
  controls,
  resetValidation,
  helperText,
  error,
  autoFocus,
  maxRows,
  defaultValue,
}) => {
  const [countryCode, setCountryCode] = useState<string>('');

  const [fullPhoneNumber, setFullPhoneNumber] = useState('');
  const [externalPhoneCode, setExternalPhoneCode] = useState<string>('');
  const [internalPhoneNumber, setInternalPhoneNumber] = useState<string>('');

  const phoneConverter = useMemo(() => new PhoneConverter(), []);

  const externalCodeInputRef = useRef(null);
  const internalPhoneNumberInputRef = useRef(null);

  useEffect(() => {
    if (defaultValue) {
      handleUpdatePhone(defaultValue, '');
    }
  }, []);

  const focusToExternalCode = () => {
    setTimeout(() => {
      // @ts-ignore
      externalCodeInputRef.current.focus();
    }, 0);
  };

  const focusToInternalPhone = () => {
    setTimeout(() => {
      // @ts-ignore
      internalPhoneNumberInputRef.current.focus();
    }, 0);
  };

  const handleUpdatePhone = (externalCode: string, internalPhone: string) => {
    const { countryCode, phoneCode, phoneNumber } = phoneConverter.parsePhoneNumber(
      `${externalCode}${internalPhone}`,
    );
    setFullPhoneNumber(`${externalCode}${internalPhone}`);
    controls.onChange({
      target: { name: 'phoneNumber', value: `${externalCode}${internalPhone}` },
      type: 'text',
    });

    if (phoneCode && phoneNumber) {
      setCountryCode(countryCode || ' ');
      setExternalPhoneCode(`+${phoneCode}`);
      setInternalPhoneNumber(phoneNumber);
      focusToInternalPhone();
    } else {
      setCountryCode('');
    }
  };

  const onChangeExternalCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetValidation && resetValidation();
    let value = `+${e.target.value.replace(/[^\d.]/g, '')}`;
    if (!countryCode) {
      if (value.length > 5) {
        const lastPart = value.substring(4, value.length);
        setInternalPhoneNumber(internalPhoneNumber + lastPart);
        value = value.substring(0, 4);
        focusToInternalPhone();
      }
    } else {
      const lastPart = value.substring(externalPhoneCode.length, value.length);
      setInternalPhoneNumber(internalPhoneNumber + lastPart);
      value = value.substring(0, externalPhoneCode.length);
      focusToInternalPhone();
    }

    setExternalPhoneCode(value);
    handleUpdatePhone(value, internalPhoneNumber);
  };

  const onChangeInternalPhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetValidation && resetValidation();
    const value = e.target.value.replace(/[^\d.]/g, '');
    if (value.length === 0) {
      focusToExternalCode();
    }
    setInternalPhoneNumber(value);
    handleUpdatePhone(externalPhoneCode, value);
  };

  const onFocusExternalCode = () => {
    if (countryCode) {
      focusToInternalPhone();
    }
  };

  const onFocusInternalPhoneNumber = () => {
    if (!countryCode && externalPhoneCode.length < 4) {
      focusToExternalCode();
    }
  };

  const removeValue = () => {
    resetValidation && resetValidation();
    setFullPhoneNumber('');
    setExternalPhoneCode('');
    setInternalPhoneNumber('');
    setCountryCode('');
  };

  const getFlagAndronment = () => {
    return (
      <InputAdornment position="start">
        <Flag code={countryCode} />
      </InputAdornment>
    );
  };

  const getAndronment = () => {
    if (fullPhoneNumber) {
      return (
        <InputAdornment position="end">
          <RemoveButton onClick={removeValue} />
        </InputAdornment>
      );
    }
    return null;
  };

  return (
    <FormControl className={classes.control} error={error} hiddenLabel>
      <div className={classes.input}>
        <TextField
          className={classes.inputCountryCode}
          onFocus={onFocusExternalCode}
          autoFocus={autoFocus}
          onChange={onChangeExternalCode}
          variant={'outlined'}
          inputProps={{
            inputMode: 'numeric',
          }}
          type={'tel'}
          multiline={false}
          maxRows={maxRows || 0}
          inputRef={externalCodeInputRef}
          value={externalPhoneCode}
          InputProps={{
            startAdornment: getFlagAndronment(),
          }}
          placeholder={'+'}
        />
        <TextField
          className={classes.inputPhoneNumber}
          onFocus={onFocusInternalPhoneNumber}
          autoFocus={false}
          onChange={onChangeInternalPhoneNumber}
          variant={'outlined'}
          inputProps={{
            inputMode: 'numeric',
          }}
          type={'tel'}
          inputRef={internalPhoneNumberInputRef}
          multiline={false}
          maxRows={maxRows || 0}
          value={internalPhoneNumber}
          InputProps={{
            endAdornment: getAndronment(),
          }}
        />
      </div>
      <input
        {...controls}
        className={classes.hidden}
        type={'text'}
        name={'phoneNumber'}
        value={fullPhoneNumber}
      />
      {helperText && helperText !== 'empty' && (
        <StyledFormHelperText>{helperText}</StyledFormHelperText>
      )}
    </FormControl>
  );
};

export const InputPhone = appWithStyles(styles)(InputPhoneComponent);
