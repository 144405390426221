import { appCreateStyles } from '@core/theme/utils/create-styles';
import { appWithStyles } from '@core/theme/utils/with-styles';
import { FormHelperText } from '@mui/material';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export const styles = () =>
  appCreateStyles({
    root: {
      width: '100%',
    },
    input: {
      position: 'relative',
      height: '56px',
      width: '100%',
    },
    inputCountryCode: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '108px',
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '12px 0px 0px 12px',
      },

      '& .MuiOutlinedInput-input': {
        paddingLeft: '6px',
      },
    },
    inputPhoneNumber: {
      position: 'absolute',
      top: '0px',
      left: '108px',
      width: 'calc(100% - 108px)',
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '0px 12px 12px 0px',
        borderLeft: 'none',
      },
    },
    hidden: {
      visibility: 'hidden',
    },
  });
export const StyledFormHelperText = appWithStyles(() => ({
  root: {
    color: _var(CSS_VARIABLES.colorError500),
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '165%',
    letterSpacing: '0.2px',
    marginLeft: '0px',
    marginRight: '0px',
    top: '60px',
    position: 'absolute',
  },
}))(FormHelperText);
