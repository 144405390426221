import React from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import ArrowRight from './img/button-right.svg';

import { styles } from './input-select-button.styles';

export type InputSelectButtonProps = AppWithStyles<any> & {
  title: string;
  description: string;
  onToggle: () => void;
  value: string;
};

const InputSelectButtonComponent: React.FC<InputSelectButtonProps> = ({
  classes,
  title,
  description,
  value,
  onToggle,
}) => {
  return (
    <div className={classes.root} onClick={onToggle}>
      <div className={classes.inputBlock}>
        <div className={classes.legend}>
          <div className={classes.currency}>{title}</div>
          <div className={classes.legendText}>{description}</div>
        </div>
        <div className={classes.value}>{value}</div>
      </div>
      <div className={classes.arrow}>
        <img src={String(ArrowRight)} />
      </div>
    </div>
  );
};

export const InputSelectButton = appWithStyles(styles)(InputSelectButtonComponent);
