import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export const styles = (theme: Theme) =>
  appCreateStyles({
    root: {
      position: 'relative',
      width: '100%',
      border: 'none',
      background: '#1E1D36',
      borderRadius: '12px',
      height: '67px',
      padding: '0px 40px 0px 20px',
      [theme.breakpoints.up(Layout.laptop)]: {
        border: '1px solid #43335F',
        background: '#372753',
      },
    },
    inputBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      color: _var(CSS_VARIABLES.colorPrimary100),
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '26px',
      letterSpacing: '0.09px',
      height: '67px',
      boxSizing: 'border-box',
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0px',
      },
      [theme.breakpoints.up(Layout.laptop)]: {
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${_var(CSS_VARIABLES.colorGrey200)} !important`,
        },
        '& input': {
          color: '#fff',
        },
      },
    },
    legend: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    value: {
      display: 'flex',
      alignItems: 'center',
      color: _var(CSS_VARIABLES.colorPrimary100),
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.08px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
      },
    },
    legendText: {
      color: '#5A5B71',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '160%',
      letterSpacing: '0.06px',
    },
    arrow: {
      position: 'absolute',
      right: '20px',
      top: '22px',
    },
  });
