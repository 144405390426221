import { appCreateStyles } from '@core/theme/utils/create-styles';
import { IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import { AppSvgIcon } from '@shared/components/svg-icon';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export const styles = () =>
  appCreateStyles({
    root: {
      width: '20px',
      height: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: _var(CSS_VARIABLES.colorGrey100),
      borderRadius: '50%',
    },
  });

export const StyledIconButton = withStyles(() => ({
  root: {
    width: '25px',
    height: '25px',
  },
}))(IconButton);

export const StyledSvgIcon = withStyles(() => ({
  root: {
    width: '15px',
    height: '15px',
  },
}))(AppSvgIcon);
